import React, { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-scroll'
import { ReactComponent as TiktokLogo } from 'src/assets/icons/tiktok-icon.svg'
import { ReactComponent as TelegramIcon } from 'src/assets/icons/telegram-icon.svg'
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu-icon.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close-icon.svg'
import { BaseDivProps } from 'src/types'
import useScreen from 'src/hooks/useScreen'

function Header({ className }: BaseDivProps) {
  const [open, setOpen] = useState(false)
  const greaterThan2md = useScreen('md')
  return (
    <div className={clsx('w-full z-10', className)}>
      {greaterThan2md && (
        <div className='max-w-screen-base mx-auto flex justify-between items-center h-[88px] h-22 px-3'>
          <div className='w-full block flex-grow lg:flex lg:items-center lg:w-auto'>
            <div className='lg:flex-grow gap-10 flex'>
              <Link to='about' smooth={true} duration={500} className='block cursor-pointer'>
                About
              </Link>
              <Link to='buy' smooth={true} duration={500} className='block cursor-pointer'>
                How to buy
              </Link>
              <Link to='tokenomics' smooth={true} duration={500} className='block cursor-pointer'>
                Tokenomics
              </Link>
            </div>
          </div>
          <TiktokLogo className='absolute left-1/2' />
          <a className='flex min-w-[160px] gap-[10px] px-5 py-3 rounded-xl border-white border border-opacity-10 cursor-pointer hover:bg-tghover hover:border-tghover'>
            <TelegramIcon />
            <div className='white text-sm inline-block'>Join Telegram</div>
          </a>
        </div>
      )}
      {!greaterThan2md && (
        <div className='max-w-screen-base mx-auto flex justify-between items-center p-5'>
          <div className='w-full flex flex-col items-center gap-5'>
            {!open && (
              <>
                <TiktokLogo />
                <hr className='w-full opacity-10'></hr>
              </>
            )}

            <div className='w-full flex justify-between'>
              <div className='p-3 border rounded-xl border-white inset-0 border-opacity-10 backdrop-blur-sm'>
                {!open && <MenuIcon onClick={() => setOpen(true)} className='w-[18px] h-[18px]' />}
                {open && <CloseIcon onClick={() => setOpen(false)} className='w-[18px] h-[18px]' />}
              </div>
              {!open && (
                <a className='inset-0 flex items-center gap-[10px] px-5 py-3 rounded-xl border-white border-opacity-10 border cursor-pointer hover:bg-tghover hover:border-tghover backdrop-blur-sm'>
                  <TelegramIcon />
                  <div className='white text-sm inline-block'>Join Telegram</div>
                </a>
              )}
              {open && <TiktokLogo />}
            </div>
            {open && (
              <div className='absolute top-[80px] flex flex-col space-y-2 border bg-mainBg p-4 w-full gap-10 py-5 border-white border-opacity-10'>
                <Link
                  to='about'
                  smooth={true}
                  duration={500}
                  className='block cursor-pointer text-2xl'
                >
                  About
                </Link>
                <Link
                  to='buy'
                  smooth={true}
                  duration={500}
                  className='block cursor-pointer text-2xl'
                >
                  How to buy
                </Link>
                <Link
                  to='tokenomics'
                  smooth={true}
                  duration={500}
                  className='block cursor-pointer text-2xl'
                >
                  Tokenomics
                </Link>
                <a className='inset-0 flex items-center gap-[10px] px-5 py-3 rounded-xl justify-center border-white border-opacity-10 border cursor-pointer hover:bg-tghover hover:border-tghover backdrop-blur-sm'>
                  <TelegramIcon />
                  <div className='white text-md inline-block'>Join Telegram</div>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
